import React from "react"
import { useRef, useEffect, useState } from "react"

import Layout from "../components/layout"
import Main from "../components/main"
import Presentazione from "../components/presentazione"
import Ditta from "../components/ditta"
import Servizi from "../components/servizi"
import Prodotti from "../components/prodotti/prodotti"
import ChiSiamo from "../components/chisiamo"
import Contatti from "../components/contatti"
import Condizioni from "../components/condizioni"
import Menu from "../components/menu"
import SEO from "../components/seo"

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect()
  const offsetTop = ele.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}
export default () => {
  const [sezioneVisibile, setSezioneVisibile] = useState("Main")
  const mainRef = useRef(null)
  const promozioniRef = useRef(null)
  const presentazioneRef = useRef(null)
  const serviziRef = useRef(null)
  const dittaRef = useRef(null)
  const prodottiRef = useRef(null)
  const chisiamoRef = useRef(null)
  const contattiRef = useRef(null)
  const condizioniRef = useRef(null)
  const menuRef = useRef(null)

  const sezioniRefs = [
    { section: "Main", ref: mainRef },
    { section: "Promozioni", ref: promozioniRef },
    { section: "Presentazione", ref: presentazioneRef },
    { section: "Servizi", ref: serviziRef },
    { section: "Ditta", ref: dittaRef },
    { section: "Prodotti", ref: prodottiRef },
    { section: "ChiSiamo", ref: chisiamoRef },
    { section: "Contatti", ref: contattiRef },
    { section: "Condizioni", ref: condizioniRef },
  ]

  useEffect(() => {
    const handleScroll = () => {
      const { height: menuHeight } = getDimensions(menuRef.current);

      //+ window.innerHeight / 2
      const scrollPosition = window.scrollY + menuHeight + 10;


      const selected = sezioniRefs.find(({ section, ref }) => {
        const ele = ref.current
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele)
          return scrollPosition > offsetTop && scrollPosition < offsetBottom
        }
        return null;
      })
      if (selected && selected.section !== sezioneVisibile) {
        setSezioneVisibile(selected.section)
      } else if (!selected && sezioneVisibile) {
        //setSezioneVisibile(undefined)
      }
    }
 
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [sezioneVisibile, sezioniRefs])

  return ( 
    <Layout current={sezioneVisibile}>
      <SEO />  
      <Main ref={mainRef} />
      <Menu current={sezioneVisibile} direction={"landscape"} ref={menuRef} />
      <Presentazione ref={presentazioneRef} />
      <Servizi ref={serviziRef} />
      <Ditta ref={dittaRef} />
      <Prodotti ref={prodottiRef} />
      <ChiSiamo ref={chisiamoRef} />
      <Contatti ref={contattiRef} />
      <Condizioni ref={condizioniRef} />
    </Layout>
  )
}
